import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import { HelmetDatoCms } from 'gatsby-source-datocms'

const AboutPage = ({ data }) => (
  <Layout>
    <>
      <HelmetDatoCms seo={data.datoCmsAbout.seoMetaTags} />
      <div className="pa3 w-100">
        <h1 className="mt2 fw4">About</h1>
        <div
          className="w-100"
          dangerouslySetInnerHTML={{
            __html: data.datoCmsAbout.contentNode.childMarkdownRemark.html,
          }}
        />
      </div>
    </>
  </Layout>
)

export default AboutPage

export const query = graphql`
  query AboutQuery {
    datoCmsAbout {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      contentNode {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
